export default function IconSolidDelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      id="Delete-1--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Delete 1 Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.648 2.048A1.2000000000000002 1.2000000000000002 0 0 0 13.952000000000002 0.35200000000000004L8 6.304 2.048 0.35200000000000004A1.2000000000000002 1.2000000000000002 0 1 0 0.35200000000000004 2.048L6.304 8 0.35200000000000004 13.952000000000002a1.2000000000000002 1.2000000000000002 0 1 0 1.6960000000000002 1.6960000000000002L8 9.696l5.952000000000001 5.952000000000001a1.2000000000000002 1.2000000000000002 0 0 0 1.6960000000000002 -1.6960000000000002L9.696 8l5.952000000000001 -5.952000000000001Z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
  );
}
